<template>
    <div v-if="electionPart" class="p-relative">

        <!-- Тулбар -->
        <div class="top-btns-bar">
            <div class="transparent-buttons">
                <v-btn
                    :disabled="!hasChanges"
                    small
                    tile
                    text
                    @click="onSaveClick"
                >
                    <v-icon left>
                        far fa-save
                    </v-icon>
                    Сохранить
                </v-btn>
                <v-btn
                    :disabled="id === 'new' || electionPart.status !== 0"
                    small
                    tile
                    text
                    @click="onAddAddressesClick"
                >
                    <v-icon left>
                        far fa-send-back
                    </v-icon>
                    Границы
                </v-btn>
                <v-btn
                    :disabled="id === 'new'"
                    small
                    tile
                    text
                    @click="openChangeStatusDlg"
                >
                    <v-icon left>
                        far fa-clipboard-list-check
                    </v-icon>
                    Изменить статус
                </v-btn>
            </div>
        </div>

        <!--Форма-->
        <v-form class="wrapperForm" ref="form" lazy-validation>

            <v-row dense>
                <v-col cols="12" sm="12" md="12" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>
                            <v-row class="two-columns-field-in-one-box" no-gutters>
                                <v-col cols="12" sm="12" md="6">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Территориальная_единица") }}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <!--<div class="onlyReadData"></div>-->
                                            <v-autocomplete
                                                :items="regions"
                                                v-model="electionPart.regionId"
                                                item-text="name"
                                                item-value="id"
                                                required
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                :filled="'regionId' in changes"
                                                :disabled="id != 'new'"
                                                :rules="integerRule"
                                                :readonly="id == 'new' && regions.length == 1"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-row no-gutters v-if="id != 'new' && electionPart.status === 0">
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Количество_избирателей") }}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                <v-chip
                                                    class="table-label"
                                                    small
                                                    label
                                                    dark
                                                    :color="getStatusColor(electionPart.voterCount)"
                                                    @click.stop="onVotersCountClick(electionPart)"
                                                    v-tooltip.top-center="$t('Нажмите_для перехода_к_избирателям_на_этом_участке')"
                                                >
                                                    <v-icon>
                                                        fas fa-user
                                                    </v-icon>
                                                    <div>{{ electionPart.voterCount }}</div>
                                                </v-chip> 
                                            </div> 
                                        </v-col>
                                    </v-row>  
                                </v-col>
                            </v-row>

                            <v-row class="two-columns-field-in-one-box" no-gutters>
                                <v-col cols="12" sm="12" md="6">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("№_участка") }}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <v-text-field
                                                required
                                                :rules="integerRule"
                                                outlined
                                                dense
                                                v-model.number="electionPart.number"
                                                :filled="'number' in changes"
                                            >
                                            </v-text-field>  
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Статус") }}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                <v-chip
                                                    class="table-label"
                                                    small
                                                    label
                                                    :dark="getObjBadStatus(electionPart.status).theme"
                                                    :color="getObjBadStatus(electionPart.status).color"
                                                >
                                                    <div>{{ getObjBadStatus(electionPart.status).text }}</div>
                                                </v-chip>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row class="one-column-field-in-one-box" no-gutters>
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{$t("Наименование_каз")}}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="10">
                                    <!--<div class="onlyReadData"></div>-->
                                    <v-text-field
                                        required
                                        outlined
                                        dense
                                        v-model="electionPart.nameKz"
                                        :filled="'nameKz' in changes"
                                        :rules="requiredRule"
                                    >
                                    </v-text-field>  
                                </v-col>
                            </v-row>

                            <v-row class="one-column-field-in-one-box" no-gutters>
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{$t("Наименование_рус")}}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="10">
                                    <!--<div class="onlyReadData"></div>-->
                                    <v-text-field
                                        required
                                        outlined
                                        dense
                                        v-model="electionPart.nameRu"
                                        :filled="'nameRu' in changes"
                                        :rules="requiredRule"
                                    >
                                    </v-text-field>  
                                </v-col>
                            </v-row>                            
                                                 
                            <v-row class="one-column-field-in-one-box" no-gutters>
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{$t("Адрес")}}/{{$t("РКА")}}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="10">
                                    <div class="more-inputs-in-row">
                                        
                                        <SelectAddressChip
                                            v-if="!addressText"
                                            @on-select="onSelectAddressFromChip"
                                            :items-params="{ region: electionPart.regionId }"
                                        />

                                        <div class="onlyReadData">
                                            <v-text-field 
                                                v-if="addressText"                  
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :value="addressText"
                                                :placeholder="$t('Адрес')"
                                                readonly
                                                :filled="'address' in changes"
                                                clearable
                                                @click:clear="clearAdress"
                                            >
                                            </v-text-field>
                                        </div>
                                        <span>/</span>
                                        <div style="width:300px;">
                                            <v-text-field
                                                :value="electionPart.address"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :placeholder="$t('РКА')"
                                                readonly
                                                :filled="'address' in changes"
                                            >
                                            </v-text-field>
                                        </div>

                                    </div>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        

            <v-tabs
                v-model="tab"
                background-color="transparent"
                slider-size="3"
                class="tab-selector-panel tab-after-section-wrapper"
                mobile-breakpoint="0"
            >
                <v-tabs-slider color="#E43958"></v-tabs-slider>

                <v-tab
                    v-if="id != 'new'"
                >
                    {{ $t("Границы_участка") }}
                </v-tab>
                <v-tab
                    v-if="id != 'new'"
                    @click="onHistoryClicked"
                >
                    {{ $t("История_изменений") }}
                </v-tab>
            </v-tabs>

            <v-tabs-items
                v-model="tab"
                class="tab-content-panel"
            >
                <v-tab-item>
                    <!--Таблица для примера-->
                    <v-data-table
                        :headers="electionPartBordersHeaders"
                        :items="borders"
                        :options.sync="optionsBordersTable"
                        :page.sync="optionsBordersTable.page"
                        class="elevation-1 customization-list-table pagination-list-table sticky-table"
                        :items-per-page="25"
                        :footer-props="{ itemsPerPageOptions:[25, 50, 100] }"
                        @page-count="paginationBordersTablePageCount = $event"
                    >

                        <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                        <template v-slot:[`item.addressPrimary`]="{ item }">
                            <div class="etw-text-container">
                                <div class="etw-text etw-text-first-up" v-tooltip.top-center="item.addressPrimary">
                                    {{ item.addressPrimary }}
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`footer.prepend`]>
                            <v-pagination
                                v-model="optionsBordersTable.page"
                                :length="paginationBordersTablePageCount"
                                :total-visible="$listPaginationTotal"
                                color="teal"
                            ></v-pagination>
                        </template>
                    </v-data-table>
                </v-tab-item>

                <v-tab-item>
                    <!--Таблица для примера-->
                    <v-data-table
                        :headers="electionPartHistoryHeaders"
                        :items="electionPartHistory"
                        :options.sync="optionsHistoryTable"
                        :page.sync="optionsHistoryTable.page"
                        class="elevation-1 customization-list-table pagination-list-table sticky-table"
                        :items-per-page="25"
                        :footer-props="{ itemsPerPageOptions:[25, 50, 100] }"
                        @page-count="paginationHistoryTablePageCount = $event"
                    >

                        <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                        <template v-slot:[`item.fromDate`]="{ item }">
                            {{ $system.dateFormat(item.fromDate, 'DD.MM.yyyy HH:mm:ss') }}
                        </template>

                        <template v-slot:[`item.toDate`]="{ item }">
                            <template v-if="item.closed">
                                {{ $system.dateFormat(item.toDate, 'DD.MM.yyyy HH:mm:ss') }}                                        
                            </template>
                            <template v-else>
                                <div v-tooltip.top-center="$t('Текущее_состояние')">
                                    &infin;
                                </div>
                            </template>
                        </template>

                        <template v-slot:[`item.nameKz`]="{ item }">
                            <div class="etw-text-container">
                                <div class="etw-text etw-text-first-up" v-tooltip.top-center="item.nameKz">
                                    {{ item.nameKz }}
                                </div>
                            </div>
                        </template>

                        <template v-slot:[`item.nameRu`]="{ item }">
                            <div class="etw-text-container">
                                <div class="etw-text etw-text-first-up" v-tooltip.top-center="item.nameRu">
                                    {{ item.nameRu }}
                                </div>
                            </div>
                        </template>

                        <template v-slot:[`item.partAddress`]="{ item }">
                            <div class="etw-text-container">
                                <div class="etw-text etw-text-first-up" v-tooltip.top-center="item.partAddress ? item.partAddress.address : item.partRka">
                                    {{ item.partAddress ? item.partAddress.address : item.partRka }}
                                </div>
                            </div>
                        </template>
                        
                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip
                                class="table-label"
                                small
                                label
                                :dark="getObjBadStatus(item.status).theme"
                                :color="getObjBadStatus(item.status).color"
                            >
                                <div>{{ getObjBadStatus(item.status).text }}</div>
                            </v-chip>
                        </template>

                        <template v-slot:[`item.authorId`]="{ item }">
                            <div v-if="item.authorId != $system.guidEmpty()" class="elipsis-text-wrap">
                                <v-employee-chip :id="item.authorId" :name="item.authorShortName" />
                            </div>
                        </template>

                        <template v-slot:[`footer.prepend`]>
                            <v-pagination
                                v-model="optionsBordersTable.page"
                                :length="paginationHistoryTablePageCount"
                                :total-visible="$listPaginationTotal"
                                color="teal"
                            ></v-pagination>
                        </template>
                    </v-data-table> 
                </v-tab-item>

            </v-tabs-items>

        </v-form>

        <SelectAddressesDlg ref="SelectAddressesDlgRef" />
        <SelectElectionPartStatusDlg ref="SelectElectionPartStatusDlgRef" />

    </div>
    
</template>

<script>
import store from '@/store';
import i18n from '@/i18n';
import { httpAPI } from "@/api/httpAPI";
import SelectAddressesDlg from '@/components/saylau/dialogs/SelectAddressesDlg.vue';
import SelectElectionPartStatusDlg from '@/components/saylau/dialogs/SelectElectionPartStatusDlg.vue';
import SelectAddressChip from '@/components/saylau/SelectAddressChip.vue';

import { mapGetters } from 'vuex';

export default {
    name: "ElectionPart",
    components: {
        SelectAddressesDlg,
        SelectElectionPartStatusDlg,
        SelectAddressChip
    },
    props: {
        id: {
            type: String,
            default: null
        }
    },
    asyncComputed: {
        addressText: {
            async get() {
                if (!this.electionPart?.address)
                    return "";

                return (await httpAPI.get(`saylau/adressregister/${this.regionId}/${this.electionPart.address}`))?.data?.[0]?.address ?? "";
            },
            default: [],
            watch: ['address']
        }
    },
    computed: {
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            integerRule: 'getIntegerNotNullRule',
        }),
        regionId() {
            return this.electionPart?.regionId;
        },
        address() {
            return this.electionPart?.address;
        },
        electionPartBordersHeaders() {
            return [
                { text: this.$t("Улица"), value: 'street', width: '400px' },
                { text: this.$t("Дома"), value: 'houses', sortable: false }
            ];
        },
        electionPartHistoryHeaders() {
            return [
                { text: this.$t("С_даты"), value: 'fromDate', width: '140px' },
                { text: this.$t("До_даты"), value: 'toDate', width: '140px', sortable: false },
                { text: this.$t("№_участка"), value: 'number', width: '150px', sortable: false },
                { text: this.$t("Наименование_каз"), value: 'nameKz', width: '200px', sortable: false },
                { text: this.$t("Наименование_рус"), value: 'nameRu', width: '200px', sortable: false },
                { text: this.$t("Адрес"), value: 'partAddress', sortable: false },
                { text: this.$t("Статус"), value: 'status', sortable: false },
                { text: this.$t("Автор"), value: 'authorId', width: '150px', sortable: false }
            ];
        },
        difference() {
            let diffs = this.$_.differenceWith(this.$_.toPairs(this.electionPart), this.$_.toPairs(this.originElectionPart), this.$_.isEqual);
            return diffs.filter(x => x[0] != 'borders'); // ignore borders, when calculating diff
        },
        hasChanges() {
            return this.difference.length > 0 && this.$refs?.form?.validate();
        },
        changes() {
            return this.$_.fromPairs(this.difference);
        },
        borders() {
            if (!this.electionPart?.borders)
                return [];

            var groups = this.$_.groupBy(this.electionPart.borders, function(item) {
                let address = "";

                if (item.city)
                    address += `${item.city}, `;

                if (item.street)
                    address += `${item.street}`;

                return address;
            });

            return Object.keys(groups).map(key => ({ 
                street: key,
                houses: groups[key].map(d => d.house.replace('дом ','')).join(', ')
            }));
        },
    },
    data() {
        return {
            tab: null,
            regions: [],
            electionPart: null,
            originElectionPart: null,
            electionPartHistory: [],
            optionsBordersTable: {
                page: 1,
                itemsPerPage : 25,
                sortBy : ['street'],
                sortDesc : [false],
                groupBy : [],
                groupDesc : [],
                multiSort : false,
                mustSort : true 
            },
            paginationBordersTablePageCount: 0,
            optionsHistoryTable: {
                page: 1,
                itemsPerPage : 25,
                sortBy : ['fromDate', 'toDate'],
                sortDesc : [true, true],
                groupBy : [],
                groupDesc : [],
                multiSort : false,
                mustSort : true 
            },
            paginationHistoryTablePageCount: 0,
        }
    },
    methods: {
        onSelectAddressFromChip(e) {
            this.electionPart.address = e.value.rka;
        },
        async onSaveClick() {
            if (this.id === 'new') {
                let result = await httpAPI.post(`saylau/election-parts`, this.electionPart);

                if (result) {
                    this.$router.push({ path: this.$system.ModulePrefix.Saylau + result.headers.location });
                    this.$notify.success(this.$t("Избирательный_участок_создан"));
                }
                
                return;
            }

            let result = await httpAPI.patch(`saylau/election-parts/${this.id}`, this.changes);

            if (result) {
                this.originElectionPart = JSON.parse(JSON.stringify(this.electionPart));
                this.$notify.success(this.$t("Изменения_сохранены"));
            }
        },
        async onHistoryClicked() {
            let response = await httpAPI.get(`saylau/election-parts/${this.id}/history`);
            this.electionPartHistory = response?.data ?? [];
        },
        async onAddAddressesClick() {
            try 
            {
                let list =  await this.$refs.SelectAddressesDlgRef.open(this.electionPart.regionId, this.electionPart.number);
                this.$store.dispatch('setOverlayVisible', { visible: true, text: `Обновление_границ` });

                let result = await httpAPI.patch(`saylau/election-part-borders/${this.id}`, { Borders: list.borders.map(border => border.rka) });

                if (result) {
                    this.optionsBordersTable.page = 1;
                    this.electionPart.borders = list.borders.sort((a,b) => (a.addressPrimary > b.addressPrimary) ? 1 : ((b.addressPrimary > a.addressPrimary) ? -1 : 0));
                    this.$notify.success(this.$t("Границы_сохранены"));
                }
            }
            catch (error)
            {
                console.log(error);
            }
            finally
            {
                this.$store.dispatch('setOverlayVisible', { visible: false });
            }
        },
        clearAdress() {
            this.electionPart.address = "";
        },
        async openChangeStatusDlg() {
            try 
            {
                let { status } = await this.$refs.SelectElectionPartStatusDlgRef.open(this.electionPart.status);
                let electionPart = (await httpAPI.post(`saylau/election-parts/set-election-part-status`, { id: this.id, status: status }))?.data ?? null;

                if (electionPart) {
                    this.electionPart = electionPart;
                    this.originElectionPart = JSON.parse(JSON.stringify(this.electionPart));
                    this.$notify.success(this.$t("Статус_избирательного_участка_изменен"));

                    if (this.tab === 1)
                        this.electionPartHistory = (await httpAPI.get(`saylau/election-parts/${this.id}/history`)).data ?? [];
                }
            } 
            catch (ex)
            {
                if (ex.isCancelled)
                    console.log('Действие отменено');
                else
                    console.log(ex);
            }
        },
        getObjBadStatus(status){
            let text = "";
            let color = "";
            let theme = true;
            
            if (status == -1) {
                text = i18n.t("Удаленный");
                color = "#e0e0e0";
                theme = false; 
            }
            else if (status == 1) {
                text = i18n.t("Закрытый");
                color = "#878686";
            }
            else if (status == 2) {
                text = i18n.t("Расформированный");
                color = "#f04747";
            }
            else if (status == 0) {
                text = i18n.t("Открытый");
                color = "#43B581";
            }

            return { 'text': text, 'color': color, 'theme': theme };
        },
        getStatusColor(count){
            let color = "";
            if(count < 2700) {
                color  = '#43B581';
            }
            else if(count >= 2700 && count <= 3000) {
                color = '#FAA61A';
            }
            else if(count > 3000) {
               color = '#f04747'; 
            }
            return color;
        },
        onVotersCountClick(item) {
            let { number } = item;
            this.$store.commit('saylau/lists/votersInPartList/SET_EXTENDED_FILTER', null);
            this.$store.commit('saylau/lists/votersInPartList/SET_TABLE_OPTION_PAGE', 1);
            this.$store.commit('saylau/lists/votersInPartList/SET_REGION', item.regionId);
            this.$store.commit('saylau/lists/votersInPartList/SET_ELECTION_PART', number);
            this.$store.commit('saylau/lists/votersInPartList/SET_FILTER_PANEL_STATE', false);
            this.$router.push({ name: 'Voters' });
        }
    },
    async beforeRouteEnter(to, from, next) {
        let regions = await store.dispatch('saylau/references/fetchRegions');
        let electionPart = null;

        if (to.params.id === 'new')
            electionPart = { number: 0, nameKz: null, nameRu: null, address: "", regionId: regions.length == 1 ? regions[0].id : 0, status: 0, mail: null, phone: null, borders: [] };
        else
            electionPart = (await httpAPI.get(`saylau/election-parts/${to.params.id}`))?.data ?? null;
        
        if (!electionPart)
            return;

        next(vm =>
        {
            vm.originElectionPart = JSON.parse(JSON.stringify(electionPart));
            vm.electionPart = electionPart;
            vm.regions = regions
        });
    },
    async beforeRouteUpdate(to, from, next) {
        this.regions = await store.dispatch('saylau/references/fetchRegions');
        this.electionPart = null;
        
        if (to.params.id === 'new')
            this.electionPart = { number: 0, nameKz: null, nameRu: null, address: "", regionId: this.regions.length == 1 ? this.regions[0].id : 0, status: 0, mail: null, phone: null, borders: [] };
        else
            this.electionPart = (await httpAPI.get(`saylau/election-parts/${to.params.id}`))?.data ?? null;

        if (!this.electionPart)
            return;

        this.originElectionPart = JSON.parse(JSON.stringify(this.electionPart));
        next();
    },
    async created() {
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.validate();
            
        })();
        
    },
    updated() {
        if(this.electionPart !== null){
            this.$refs?.form?.validate();
        }  
        
    },
}
</script>