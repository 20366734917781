<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="1200"
    >
        <v-card class="modal-maincard">
            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t('Границы_участка') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="wrapperFormModal">

                <v-card flat>
                    <v-card-text>
                        <v-row v-if="cities.length > 0" no-gutters>
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{ $t("Населенный_пункт") }}</label>
                            </v-col>
                            <v-col  cols="12" sm="12" md="10">
                                <v-autocomplete
                                    :items="cities"
                                    v-model="city_id"
                                    item-text="name"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    append-icon="fas fa-chevron-down"
                                    @change="onChangeCity"
                                    clearable
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{ $t("Поиск") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <div class="text-field-with-icon">
                                    <v-text-field
                                        :value="FilterString"
                                        :placeholder="$t('Поиск')"
                                        hide-details
                                        dense
                                        clearable
                                        required
                                        outlined
                                        append-icon="fas fa-search"
                                        @click:clear="FilterString=''"
                                        @input="filterStringChange"
                                    ></v-text-field>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-row class="mt-4 p-relative" no-gutters>
                    <v-overlay :value="loaderLists" absolute>
                        <v-progress-circular
                            indeterminate
                            size="40"
                            width="2"
                            color="#a94442"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-col>
                        <v-card flat>
                            <v-card-text>
                                <div class="form-box-title title-with-right-part">
                                    <span>
                                        {{ $t("Доступно") }}
                                    </span>
                                </div>
                                <v-virtual-scroll
                                    :items="listLeft"
                                    :item-height="40"
                                    :height="windowHeight >= 937 ? '400' : '200'"
                                    :bench="10"
                                    class="virt-scrl-list-tree"
                                >
                                    <template v-slot:default="{ item, index }">
                                    
                                        <v-list-item
                                            :key="index"
                                            @click="onItemLeftClick(item)"
                                        >
                                            <v-list-item-action class="group-item-action">
                                                <v-checkbox
                                                    v-if="!item.electionPartNumber || item.electionPartNumber==electionpart_number"
                                                    color="primary"
                                                    hide-details
                                                    class="p-0 allow-parent-selection-checkbox"
                                                    :input-value="isItemLeftSelected(item)"
                                                    @click.native.capture="onItemLeftSelect(item, $event)"
                                                >
                                                </v-checkbox>
                                                <div v-else class="fake-checkbox-first-level">
                                                    <v-chip
                                                        class="table-label chip-instead-checkbox"                            
                                                        small
                                                        label                            
                                                        outlined
                                                        color="#000"
                                                        text-color="#000"
                                                    >
                                                        <div>{{ getElectionPartNumber(item) }}</div>
                                                    </v-chip>
                                                </div>
                                            </v-list-item-action>
                                            
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <div class="d-flex" >
                                                        <!-- Населенный пункт, улица -->
                                                        <div class="d-flex align-center flex-grow-1">
                                                            <div class="d-flex flex-column flex-grow-1">
                                                                <div
                                                                    class="etw-text-container etw-t-c-many-rows"
                                                                    v-tooltip="{
                                                                        content: item.street,
                                                                        boundariesElement: boundariesElementTooltips
                                                                    }"
                                                                >
                                                                    <span class="etw-text" v-html="item.street"></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Дом -->
                                                        <div 
                                                            class="d-flex align-center"
                                                            style="width: 100px"
                                                        >
                                                            <div 
                                                                class="etw-text-container"
                                                                v-tooltip="{
                                                                    content: item.house,
                                                                    boundariesElement: boundariesElementTooltips
                                                                }"
                                                            >
                                                                <span class="etw-text" v-html="item.house"></span>
                                                            </div>
                                                        </div>

                                                        <!-- Количество избирателей -->
                                                        <div 
                                                            class="d-flex align-center"
                                                            style="width: 53px"
                                                        >
                                                            <v-chip
                                                                class="table-label"
                                                                small
                                                                label
                                                            >
                                                                <v-icon>
                                                                    fas fa-user
                                                                </v-icon>
                                                                <div>{{ item.residents }}</div>
                                                            </v-chip>
                                                        </div>
                                                    </div>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-virtual-scroll>
                            </v-card-text>
                        </v-card>   
                    </v-col>
                    <v-col md="auto" class="d-flex pa-2">
                        <div class="d-flex flex-column mt-auto mb-auto">
                            <v-btn
                                :disabled="!allowAddAll" 
                                class="mb-4"
                                icon
                                color="teal"
                                v-tooltip.top-center='$t("Добавить_все")'
                                @click="allToSelected"
                                x-small
                            >
                                <v-icon>far fa-chevron-double-right</v-icon>
                            </v-btn>
                            <v-btn 
                                :disabled="selectedLeft.length == 0"
                                class="mb-2"
                                icon
                                color="teal"
                                v-tooltip.top-center='$t("Добавить")'
                                @click="toSelected"
                                x-small
                            >
                                <v-icon>far fa-chevron-right</v-icon>
                            </v-btn>
                            <v-btn 
                                :disabled="selectedRight.length == 0"
                                class="mt-2"
                                icon
                                color="teal"
                                v-tooltip.top-center='$t("Исключить")'
                                @click="fromSelected"
                                x-small
                            >
                                <v-icon>far fa-chevron-left</v-icon>
                            </v-btn>
                            <v-btn 
                                :disabled="listRight.length == 0" 
                                class="mt-4"
                                icon
                                color="teal"
                                v-tooltip.top-center='$t("Исключить_все")'
                                @click="allFromSelected"
                                x-small
                            >
                                <v-icon>far fa-chevron-double-left</v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col>
                        <v-card flat>
                            <v-card-text>
                                <div class="form-box-title title-with-right-part">
                                    <span>
                                        {{ $t("Выбрано") }}
                                    </span>
                                </div>
                                <v-virtual-scroll
                                    :items="listRight"
                                    :item-height="40"
                                    :height="windowHeight >= 937 ? '400' : '200'"
                                    :bench="10"
                                    class="virt-scrl-list-tree"
                                >
                                    <template v-slot:default="{ item, index }">
                                    
                                        <v-list-item
                                            :key="index"
                                            @click="onItemRightClick(item)"
                                        >
                                            <v-list-item-action class="group-item-action">
                                                <v-checkbox
                                                    color="primary"
                                                    hide-details
                                                    class="p-0 allow-parent-selection-checkbox"
                                                    :input-value="isItemRightSelected(item)"
                                                    @click.native.capture="onItemRightSelect(item, $event)"
                                                >
                                                </v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <div class="d-flex" >
                                                        <!-- Населенный пункт, улица -->
                                                        <div
                                                            class="d-flex align-center flex-grow-1"
                                                        >
                                                            <div class="d-flex flex-column flex-grow-1">
                                                                <div
                                                                    class="etw-text-container etw-t-c-many-rows"
                                                                    v-tooltip="{
                                                                        content: item.street,
                                                                        boundariesElement: boundariesElementTooltips
                                                                    }"
                                                                >
                                                                    <span class="etw-text" v-html="item.street"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Дом -->
                                                        <div 
                                                            class="d-flex align-center"
                                                            style="width: 100px"
                                                        >
                                                            <div 
                                                                class="etw-text-container"
                                                                v-tooltip="{
                                                                    content: item.house,
                                                                    boundariesElement: boundariesElementTooltips
                                                                }"
                                                            >
                                                                <span class="etw-text" v-html="item.house"></span>
                                                            </div>
                                                        </div>
                                                        <!-- Количество избирателей -->
                                                        <div 
                                                            class="d-flex align-center"
                                                            style="width: 53px"
                                                        >
                                                            <v-chip
                                                                class="table-label"
                                                                small
                                                                label
                                                            >
                                                                <v-icon>
                                                                    fas fa-user
                                                                </v-icon>
                                                                <div>{{ item.residents }}</div>
                                                            </v-chip>
                                                        </div>
                                                    </div>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-virtual-scroll>
                            </v-card-text>
                        </v-card>   
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        v-if="hasDifferent"
                        color="cyan" 
                        text
                        depressed 
                        @click="ok" 
                    >
                        {{$t("Применить")}}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>
import { httpAPI } from "@/api/httpAPI";
import _ from 'lodash';
import sys from '@/services/system';

export default {
    name: "SelectAddresses",
    data() {
        return {
            listLeft: [],
            listRight: [],
            electionpart_number: null,
            //rightFiltredList: [],
            visible: false,
            resolve: null,
            reject: null,
            region_id: null,
            city_id: null,
            FilterString: '',
            cities: [],
            selectedLeft: [],
            selectedRight: [],
            loaderLists: false,
            boundariesElementTooltips:  document.querySelector('.virt-scrl-list-tree'),
            originalSelectedRKA: null
        }
    },
    computed:{
        windowHeight() {
            return window.innerHeight;
        },
        allowAddAll(){
            return this.listLeft.find(item => !item.electionPartNumber || item.electionPartNumber == this.electionpart_number);
        },
        hasDifferent(){
            return !this.loaderLists && this.originalSelectedRKA != JSON.stringify(this.listRight.map(item=>item.rka).sort());
        },
        allowtypes(){
            return this.listLeft.map(item => item.house.split(' ')[0]).filter(this.onlyUnique);
        }
    },
    methods: {
        //#region DialogActions
        async open(regionId, electionPartNumber){
            this.listLeft = [];
            this.listRight = [];
            this.FilterString = null;
            //this.rightFiltredList = [];
            this.cities = [];
            this.selectedLeft = [];
            this.selectedRight = [];
            this.electionpart_number = electionPartNumber;
            this.region_id = regionId;
            this.city_id = null;
            if (this.region_id){
                httpAPI
                    .get(`saylau/adressregister/cities/${this.region_id}`)
                    .then(response =>
                        {
                            this.cities = response.data;
                        });
                httpAPI
                    .get(`saylau/adressregister/select?electionpartnumber=${electionPartNumber}&selected=true`)
                    .then(response =>
                        {
                            this.listRight = response.data;
                            this.originalSelectedRKA = JSON.stringify(this.listRight.map(item=>item.rka).sort());
                        });
            }
            this.visible = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        ok() {
            this.visible = false;
            this.resolve({
                borders: this.listRight
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });
        },
        async updateLeftList(){
            this.listLeft = [];
            if ((this.cities.length > 0 && !this.city_id) || (this.cities.length == 0 && !this.FilterString))
                return;
            this.loaderLists = true;
            let url =`saylau/adressregister/select?electionpartnumber=${this.electionpart_number}&selected=false`;
            if (this.city_id){
                url += `&city=${this.city_id}`;
            }
            if (this.FilterString){
                url += `&search=${this.FilterString}`;
            }
            
            httpAPI
                .get(url)
                .then(response =>
                    {
                        let list = response.data.filter(item => !this.listRight.map(ritem => ritem.rka).includes(item.rka));
                        let words = sys.getNormalizedArray(this.FilterString);
                        
                        if (words.length == 1 && words[0].length == 16 && !isNaN(words[0]))
                            this.listLeft = list; //Если поиск по РКА? то сервер вернет только одно точное совпадение
                        else 
                            this.listLeft = sys.checkSearchWithScoreGetArray(this.FilterString, list, 'street', 'house');
                        //this.listLeft = list.filter(item => !item.house.includes('гараж') && !item.house.includes('строение'));
                        this.loaderLists = false;
                    });
        },
        async onChangeCity(item){
            this.city_id = item;
            this.updateLeftList();
        },
        //#endregion

        filterStringChange: _.debounce(function (value) {
            this.FilterString = value;
            this.updateLeftList();
        }, 300),

        /*applyFilterForRightList(){
            if (this.city_id)
                this.rightFiltredList = sys.checkSearchWithScoreGetArray(this.FilterString, this.listRight.filter(item => item.cityId==this.city_id), 'street', 'house');
            else if (this.cities.length == 0)
                this.rightFiltredList = sys.checkSearchWithScoreGetArray(this.FilterString, this.listRight, 'street', 'house');
            else 
                this.rightFiltredList = []
        },*/

        //#region LeftListAction
        onItemLeftClick(item) {
            this.onItemLeftSelect(item);
        },
        onItemLeftSelect(item, event) {
            if (item.electionPartNumber && item.electionPartNumber!=this.electionpart_number)
                return;
            let index = this.selectedLeft.indexOf(item.rka);
            if (index > -1)
                this.selectedLeft.splice(index, 1);
            else 
                this.selectedLeft.push(item.rka);
            if (event)
                event.stopPropagation();
        },
        isItemLeftSelected(item) {
            return this.selectedLeft.includes(item.rka);
        },
        //#endregion

        //#region RightListAction
        onItemRightClick(item) {
            this.onItemRightSelect(item);
        },
        onItemRightSelect(item, event) {
            let index = this.selectedRight.indexOf(item.rka);
            if (index > -1)
                this.selectedRight.splice(index, 1);
            else
                this.selectedRight.push(item.rka);
            if (event)
                event.stopPropagation();
        },
        isItemRightSelected(item) {
            return this.selectedRight.includes(item.rka);
        },
        //#endregion
        
        //#region Добавить в выбранные
        toSelected(){
            if(this.selectedLeft.length > 0){
                this.listRight.push(...this.listLeft.filter(item => this.selectedLeft.includes(item.rka)))
                this.listRight.sort(this.compareAddresses);
                //this.applyFilterForRightList();
                this.listLeft = this.listLeft.filter(item => !this.selectedLeft.includes(item.rka));
                this.selectedLeft = [];
            }
        },
        async allToSelected(){
            this.$notify.confirm(
                this.$t('Вы_действительно_хотите_переместить_все_доступные_элементы'),
                async () =>
                {
                    this.loaderLists = true;
                    setTimeout(() => {
                        let selRKAs = this.listLeft.filter(item => !item.electionPartNumber || item.electionPartNumber==this.electionpart_number).map(item => item.rka);
                        this.listRight.push(...this.listLeft.filter(item => selRKAs.includes(item.rka)))
                        this.listRight.sort(this.compareAddresses);
                        //this.applyFilterForRightList();
                        this.listLeft = this.listLeft.filter(item => !selRKAs.includes(item.rka));
                        this.selectedLeft = [];
                        this.selectedRight = [];
                        this.loaderLists = false;
                    }, 500);
                }
            );
        },
        //#endregion

        //#region Исключить из выбранных
        fromSelected(){
            if(this.selectedRight.length > 0){
                //this.listLeft.push(...this.listRight.filter(item => this.selectedRight.includes(item.rka)));
                //this.listLeft.sort(this.compareAddresses);
                this.listRight = this.listRight.filter(item => !this.selectedRight.includes(item.rka));
                this.updateLeftList()
                //this.applyFilterForRightList();
                this.selectedRight = [];
            }
        },
        async allFromSelected(){
            this.loaderLists = true;
            setTimeout(() => {
                //this.listLeft.push(...this.listRight);
                //this.listLeft.sort(this.compareAddresses);
                this.listRight = [];
                this.updateLeftList();
                /*let selRKAs = this.rightFiltredList.map(item => item.rka);
                this.listLeft.push(...this.rightFiltredList);
                this.listLeft.sort(this.compareAddresses);
                this.listRight = this.listRight.filter(item => !selRKAs.includes(item.rka));
                this.applyFilterForRightList();*/
                this.selectedLeft = [];
                this.selectedRight = [];
                this.loaderLists = false;
            }, 500);
        },
        //#endregion
        compareAddresses(a, b){
            let avalues = [/*a.city?.toLowerCase(),*/ a.street?.toLowerCase(), a.houseNumber, a.house];
            let bvalues = [/*b.city?.toLowerCase(),*/ b.street?.toLowerCase(), b.houseNumber, b.house];
            for (let i=0; i < 4; i++){
                if (avalues[i] < bvalues[i]) return -1;
                if (avalues[i] > bvalues[i]) return 1;
            }
            return 0;
        },
        getAddressName(item){
            if (item.city)
                return `${item.city}, ${item.street}, ${item.house}`;
            else 
                return `${item.street}, ${item.house}`;
        },
        getSelectedAddress(item){
            return {addressPrimaryRKA: item.rka, addressPrimary: this.getAddressName(item)};
        },
        getElectionPartNumber(item){
            return item.electionPartNumber != this.electionpart_number ? item.electionPartNumber : null;
        },
        firstCharToUpperCase(str) {
            let firstChar = str.charAt(0);
            let upperFirstChar = firstChar.toUpperCase();
            let restOfString = str.slice(1);
            return upperFirstChar + restOfString;
        },
        onlyUnique(value, index, array) {
            return array.indexOf(value) === index;
        }
    },
    watch: {
    },
}
</script>