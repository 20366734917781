<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t(this.title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{$t('Статус')}}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-select
                                        v-model="status"
                                        :items="statuses"
                                        item-text="name" 
                                        item-value="id"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        append-icon="fas fa-chevron-down"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>  
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="status != null"
                        color="cyan" 
                        text 
                        depressed 
                        @click="save" 
                        >
                        {{$t("Сохранить")}}
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: "SelectElectionPartStatusDlg",
    computed: {
        statuses(){
            let statuses =
            [
                //{ id: -1, name: this.$t('Удален') },
                { id: 0, name: this.$t('Открытый') },
                { id: 1, name: this.$t('Закрытый') },
                { id: 2, name: this.$t('Расформированный') }
            ];

            return statuses.filter(status => status.id != this.currentStatus);
        }
    },
    data () {
        return {
            title: "Изменить_статус_участка",
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            status: null,
            currentStatus: null
        }
    },
    methods: {
        async open(currentStatus) {
            this.status = null;
            this.visible = true;
            this.currentStatus = currentStatus;
            
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        save() {
            this.visible = false;
            this.resolve({ status: this.status });
        },
        cancel() {
            this.visible = false;
            this.reject({ isCancelled: true, message: "Действие_отменено" }); 
        }
    }
}
</script>