<template>
    <div class="chip-acmpl-main-wrap">
        <v-chip
            :class="['person-label', chipMenuModel ? 'chip-acmpl-active' : '', required ? 'required-chip' : '']"
            small
            label
            outlined
            color="#9e9e9e"
        >
            <v-text-field
                :value="chipAutocompleteSearch"
                :placeholder="'🔍︎ ' + $t('Добавить')"
                hide-details
                dense
                class="mt-0 chip-acmpl-input"
                @input="onChipAutocompleteInput($event)"
                ref="chipInputRef"
                @keydown.40="moveDown" 
                @keydown.38="moveUp"
                @keydown.13="itemClick(items[selectedIndex], true)"
                :autofocus="isNeedToSetFocus"
            ></v-text-field>
        </v-chip>
        <div
            v-show="chipMenuModel"
            :class="['cacd-wrap', positionDropdown]"
            ref="menuRef"
        >
        
            <v-virtual-scroll
                v-if="items.length > 0 && !isLoadingData"
                :items="items"
                :item-height="40"
                :bench="10"
                :class="['virt-scrl-list-tree', 'virt-scrl-list-tree-' + idVScroll]"
                :max-height="160"
            >
                <template v-slot:default="{ item, index }">
                    <v-list-item
                        :key="index"
                        :value="item"
                        @click="itemClick(item)"
                        @mouseover="itemMouseover(index)"
                        :class="{activeListItem: selectedIndex === index}"
                        v-tooltip="{ 
                            content: listItemTooltip(item), 
                            show: selectedIndex === index && chipMenuModel, 
                            placement: 'left',
                            trigger: 'manual'
                        }"
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                <span
                                    :class="['font-weight-medium', 'text-uppercase']"
                                    v-html="item.address"
                                >
                                </span>
                            </v-list-item-title>
                        </v-list-item-content>
                </v-list-item>
            </template>
            </v-virtual-scroll>
            <div
                v-else-if="isLoadingData"
                class="div-table-row"
            >
                <div class="cfl-placeholder">
                    {{ $t("Загрузка") }}
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="2"
                    ></v-progress-linear>
                </div>
            </div>
            <div
                v-else
                class="div-table-row"
            >
                <div class="cfl-placeholder">{{ $t("К_сожалению,_по_Вашему_запросу_ничего_не_найдено") }}</div>
            </div>
        
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import sys from '@/services/system';
import _ from 'lodash';

export default {
    name: "SelectAddressChip",
    props: {
        targetType: {
            type: String,
            default: null
        },
        itemsParams: {
            type: Object,
            default: () => null
        },
        positionDropdown: {
          type: String,
          default: 'bottom'
        },
        required: {
          type: Boolean,
          default: false
        }
    },
    data: () => ({
        chipMenuModel: false,
        chipAutocompleteSearch: "",
        contBox: document.getElementsByClassName('content-box')[0],
        selectedIndex: 0,
        idVScroll: window.crypto.getRandomValues(new Uint16Array(1))[0],
    }),
    computed: {
        ...mapGetters('saylau/components/selectAddressChip',
            { 
                allowedItems: 'getItems', //данные без учета поиска
                isLoadingData: 'isLoading', //признак, что данные сейчас загружаются
                isNeedToSetFocus: 'isNeedToSetFocus' //признак фокуса на поле
            }),
        items() {
            return sys.checkSearchWithScoreGetArray(this.chipAutocompleteSearch, this.allowedItems, "address");
        }
    },
    methods: {
        ...mapActions('saylau/components/selectAddressChip', { updateAllowedItems: 'updateItems' }),
        ...mapMutations('saylau/components/selectAddressChip', { updateAutoFocus: 'SET_AUTO_FOCUS' }),
        onChipAutocompleteInput: _.debounce(async function (value) {
            this.chipAutocompleteSearch = value;
            this.selectedIndex = 0;

            if (this.itemsParams?.region && this.chipAutocompleteSearch?.length > 2) {
                this.chipMenuModel = true;

                //позиционирование выпадающего списка(left/right)
                let screenCenter = this.contBox.clientWidth / 2;
                let element = this.$refs.chipInputRef;
                let elementOffset = this.getOffset(element.$el);

                let menu = this.$refs.menuRef;
                
                if (elementOffset.left <= screenCenter) {
                    menu.style.left = "0";
                    menu.style.right = "initial";
                    menu.style.borderTopRightRadius = "4px";
                    menu.style.borderTopLeftRadius = "0";
                }
                else {
                    menu.style.left = "initial";
                    menu.style.right = "0";
                    menu.style.borderTopRightRadius = "0";
                    menu.style.borderTopLeftRadius = "4px";
                } 

                //грузим элементы
                await this.updateAllowedItems({ params: this.itemsParams });

                //если элемент расположен слишком близко к нижней части экрана, то промотаем главный контейнер в самый низ автоматически
                if ((this.contBox.clientHeight - elementOffset.top) < 200) {
                    this.contBox.scrollTo(0, this.contBox.scrollHeight);
                }
            }
            else {
                this.chipMenuModel = false;
            }
        }, 250),
        itemClick(selected, keyboardCall = false) {
                 
            if (!selected)
                return;
            
            let member = selected;

            if (member)
                this.$emit('on-select', { value: member});
            
            this.clearState();

            if (keyboardCall) {
                this.updateAutoFocus(true);
            }
        },
        itemMouseover(id){
          this.selectedIndex = id;
        },
        getOffset(el) {
            const rect = el.getBoundingClientRect();
            return {
              left: rect.left + window.scrollX,
              top: rect.top + window.scrollY
            };
        },
        moveDown(e) {
          e.preventDefault();
          if (this.selectedIndex + 2 <= this.items.length) {
            this.selectedIndex++;
            this.scrollListMenu(this.selectedIndex * 40);
          }
        },
        moveUp(e) {
          e.preventDefault();
          if (this.selectedIndex > 0) {
            this.selectedIndex--;
            this.scrollListMenu(this.selectedIndex * 40);
          }
        },
        scrollListMenu(offset){
            const root = document.querySelector(".virt-scrl-list-tree" + '-' + this.idVScroll);
            if(root){
                root.scrollTo({
                    top: offset,
                    behavior: 'smooth',
                });
            }  
        },
        clearState (){
          this.chipMenuModel = false;
          this.chipAutocompleteSearch = "";
          this.selectedIndex = 0;
        },
        listItemTooltip(item){
            let resultStr = '<div style="text-align:left;">';
            resultStr += item.address ? '<span style="font-weight:bold;text-transform:uppercase;">' + item.address + '</span>' : "";
            resultStr += '</div>';
            return resultStr;
        }
    },
    created () {
      document?.addEventListener('click', (e) => {
        //закрываем облачко и сбрасываем поиск если клик был по input
        if(e?.target?.value != this.chipAutocompleteSearch){
          this.clearState();
        }
      });
    },
    watch: {},
    beforeDestroy(){
      this.updateAutoFocus(false);
    }
}
</script>